.jumbotron-header {
  background-color: rgba(0, 0, 0, .6);
  position: relative;
  &.no-darken {
    background-color: transparent;
  }
  &::after {
    content: "";
    //background-image: url("../img/banner.jpg");
    background-image: url("../img/espana.jpg");
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}
